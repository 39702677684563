
// main layout scss
#mytask-layout{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-orient: vertical; */
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

// left icon navigation bar 
.navigation{
    background-color: var(--card-color);
    width: 60px;
    height: 100vh;
    font-size: 1rem;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    z-index: 9;

    @media only screen and (max-width: $break-medium - 1px) {
        width: 100%;
        height: 60px;
        flex-direction: row;
        align-items: center;
        position: fixed;
        overflow: hidden;

        .brand-icon{
            svg{
                width: 22px;
                margin-right: 20px;
            }
        }
        .navbar-nav{
            justify-content: flex-end !important;
            padding-right: 50px;
            flex-direction: row !important;
            .flex-grow-1{
                flex-grow: unset !important;
            }
            .nav-link{
                margin-left: 5px;
            }
            .menu-toggle{
                position: fixed;
                right: 6px;
            }
        }
    }
}

// sidebar main div
.sidebar{
    @include transition(ease .1s);
    @include c_overflow(scroll); 
    background: var(--primary-color);
    z-index: 99999;
    margin: 25px;
    height: calc(100vh - 50px);
    width: 260px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    border-radius: 1.1rem;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;

    @media only screen and (max-width: $break-1280 - 1px) {
        position: fixed;
        left: -400px;
        height:100vh;
        margin: 0px;
        border-radius: 0px;
    }

    @media only screen and (max-width: 375px) {
        height:100%;
    }

    .form-switch{
        label{
            color: var(--white-color);
            font-size: 15px;
            padding-left: 10px;
        }
        &.theme-rtl{
            padding-left: 2em;
        }
    }

    .sidebar-title{
        color: var(--white-color);
    }

    .menu-list{
        list-style: none;
        padding: 0;
        li[aria-expanded="true"]{
            .m-link{
                color: var(--secondary-color);
            }
            .arrow:before{
                content: "\f10c";
            }
            .sub-menu:before{
                background-color: var(--secondary-color);
            }
        }
        .m-link,
        .ms-link{
            display: flex;
            &:hover,
            &.active{
                color: var(--secondary-color);
                &::before{
                    display: block;
                }
            }
        }
        .m-link{
            color: var(--white-color);
            align-items: center;
            padding: 10px 0;
            font-size: 17px;
            @media only screen and (max-width: 767px){
                font-size: 15px;
                padding: 8px 0;
            }
        }
        .ms-link{
            color: var(--white-color);
            position: relative;
            padding: 5px 0;
           span{
                font-size: 14px;
            }
        }

        .fa {
            width: 30px;
        }
        .arrow{
            color: var(--white-color);
            opacity: 0.3;
            font-size: 10px;
        }
        .divider{
            color: var(--white-color);
            border-color: rgba($white, 0.3) !important;
            opacity: 0.5;
        }
        [class^="icofont-"]{
            width: 30px;
        }
        .sub-menu{
            list-style: none;
            position: relative;
            padding-left: 30px;

            &::before{
                background-color: var(--white-color);
                content: "";
                position: absolute;
                height: 100%;
                width: 0px;
                left: 8px;
                top: 0;
            }
            .ms-link::before{
                top:10px;
            }
        }
    }
    .sidebar-mini-btn{
        font-size: 34px;
        &:focus,&:active{
            outline: none !important;
            box-shadow: none !important
        }
        .icofont-bubble-right{
            &:before{
                content: "\ea64";
            }
        }
        @media only screen and (max-width:  $break-small - 1px) {
           display: none;
        }
    }

    &.gradient{
        background: var(--primary-gradient);
        &.sidebar-mini{
            .menu-list{
                .sub-menu{
                    background: var(--primary-gradient);
                }
            }
        }
    }
    
    &.sidebar-mini{
        text-align: center;
        padding: 1.5rem 0 !important;
        width: 100px;
        z-index: 9999;
        overflow: visible;  
        .brand-icon{
            display: block;
            .logo-icon{
                margin: 0 auto;
            }
            .logo-text{
                padding-left: 0px;
                padding-top: 8px;
            }
        }
        .sidebar-title span,
        form{
            display: none;
        }

        .menu-list{
            > li {
                position: relative;

                &:hover{
                    .sub-menu{
                        display: block !important;
                    }
                }
            }

            .m-link{
                margin-bottom: 10px;
                span{
                    display: none;
                }
                &:hover{
                    span{
                        display: block;
                        background: var(--primary-color);
                        border-radius: .25rem;
                        box-shadow: 0 10px 40px 10px rgba($dark,.2);
                        width: 200px;
                        padding: 10px 0px 10px 30px;
                        text-align: left;
                        position: absolute;
                        left: 101px;
                        top: 0;
                        font-size: 17px;
                        &.badge{
                            font-size: 13px;
                            &:before{
                                top: 12px;
                            }
                        }
                    }
                }
            }
            .ms-link{
                &::before{
                    left: -16px;
                }
                span{
                    font-size: 17px;
                }
            }
            .fa{
                width: 100%;
            }
            .sub-menu{
                background: var(--primary-color);
                border-radius: .25rem;
                box-shadow: 0 10px 40px 10px rgba($dark,.2);
                width: 230px;
                padding-top: 10px;
                padding-bottom: 10px;
                position: absolute;
                left: 101px;
                top: 0;
                &:before{
                    display: none;
                }
                .ms-link::before{
                    top:12px;
                }
            }
            .divider small{
                display: none;
            }
            [class^="icofont-"]{
                width: 100%;
            }
        }

        .fa-arrow-left::before{
            content: "\f061";
        }

        #menu-Components{
            width: 300px;
            li{
                width: 50%;
                float: left;
            }
        }
        #menu-Authentication{
            width: 230px;
        }
        .sub-menu.show{
            display: none;
        }
        .sidebar-mini-btn{
            .icofont-bubble-right{
                &:before{
                    content: "\ea65";
                }
            }
        }
        .form-switch{
            label{
                display: none;
            }
            &.theme-rtl{
                padding-left: 2.5em;
            }
        }
    }
    
    &.open{
        left: 0px;
        @media only screen and (max-width: $break-medium - 1px) {
            left: 0;
        }
    }

    .brand-icon{  
        .logo-icon{
            @media only screen and (max-width: $break-small - 1px) {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                line-height: 40px;
            }
            svg{
                @media only screen and (max-width: $break-small - 1px) {
                    width: 25px;
                }
            }
        }
        .logo-text{
            @media only screen and (max-width: $break-small - 1px) {
               font-size: 17px;
            }
        }
    } 
}

.menu-toggle{
    color: var(--text-color);
    @media only screen and (min-width: $break-1280) {
        display: none;
    }
}

// project main body part
.main{
    @include c_overflow(scroll);
    height: 100vh;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    .body{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
    }
}

// 
.auth-h100{
    height: calc(100vh - 192px);
    @media only screen and (max-width: $break-1280) {
        height: calc(100vh - 96px);
    }

    @media only screen and (max-width: $break-xsmall) {
        height: auto;
    }
}

.sign-up-page,
.auth-h100{
    @media only screen and (max-width: 991px) {
        height: auto;
    }
}