// card 
.card{
    border-color: var(--border-color);
    background-color: var(--card-color);
    &.card-callout{
        border-left-width: .4rem;
        border-left-color: var(--primary-color);
    }
    .card-header{
        background-color: transparent;
        border-bottom: none;
        color: var(--text-color);
    }
    .card-body{
        color: var(--text-color);

        .rbt-token {
            background-color: var(--secondary-color-white);
            border: 0;
            border-radius: 0.25rem;
            color: var(--secondary-color);
            display: inline-flex;
            line-height: 1rem;
            margin: 1px 3px 2px 0;
        }

        .rbt-token:hover {
            background-color: var(--secondary-color);
            color: var(--secondary-color-white);
        }

        .rbt-token-removeable {
            cursor: pointer;
        }

        .rbt-token .rbt-token-remove-button {
            background-image: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            box-shadow: none;
            color: inherit;
            display: flex;
            justify-content: center;
            font-size: inherit;
            font-weight: normal;
            opacity: 1;
            outline: none;
            padding: 0.25rem 0.5rem;
            padding-left: 0;
            text-shadow: none;
        }

        .rbt-token .rbt-token-label:not(:last-child) {
            padding-right: 0.25rem;
        }

        .rbt-token .rbt-token-label {
            padding: 0.25rem 0.5rem;
        }

        .rbt-input-multi .rbt-input-wrapper {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
        }

        .rbt-input-multi.form-control {
            height: auto;
        }

        .rbt-input-multi {
            cursor: text;
            overflow: hidden;
            position: relative;
        }
    }
}

.card-header{
    h3{
        @media only screen and (max-width: 1024px){
            font-size: 20px;
        }
        @media only screen and (max-width: 767px){
            font-size: 18px;  
        }
    }

    button {
        z-index: 500;
    }
}